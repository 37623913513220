import Vue from 'vue'
import VueRouter from 'vue-router'
import routesList from './routers.js'
import store from '@/store'
Vue.use(VueRouter)
import { getCookies, removeCookies } from '../utils/methodCookies';
// import ll from "@/beforeunload";//引入页面刷新文件
// console.log(ll)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = routesList//.slice(0, 1).concat(ll, routesList.slice(1))//.concat(ll)
// [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   // {
//   //   path: '/about',
//   //   name: 'about',
//   //   // route level code-splitting
//   //   // this generates a separate chunk (about.[hash].js) for this route
//   //   // which is lazy-loaded when the route is visited.
//   //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   // }
// ]
const createRouter =()=> new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})
const router = createRouter()
router.afterEach((to,from)=>{
  store.commit('log/setMonitoring',{
    title:"切换页面",
    formPage:from.path,
    toPage:to.path,
  })
})
router.beforeEach((to, from, next) => {
  console.log(to, from)
  window._axiosPromiseArr.forEach((el, index) => {
    console.log(el)
    el.cancel() // 路由跳转之前，中止请求
    //  重置 window._axiosPromiseArr
    delete window._axiosPromiseArr[index]
  })
  var path=to.path.split("/")
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if (flag&&path[1]=="smart-admin") {
    //手机端
    next("/")
  }else{
    if(path[1]=="smart-admin"){
      if(getCookies('adm_name')){
        next()
      }else if (path[2]=="login"){
        next()
      }else{
        next("/smart-admin/login")
      }
    }else{
      next()
    }
  }
  // next()
 
  // if (getCookies('Admin-Token')) { // 判断缓存里面是否有 Token 
  //   resetRouter()
    
  // } else {
  //   console.log("111")
  //   //   // removeCookies()
  //   //   // router.push({ path: "/"  })
  //   if (to.path === '/login') next()
  //   else next('/login')


  // }
})
export function resetRouter() {
  const newRouter = createRouter()
  var routerL=[]
  for(var i=0;i<newRouter.options.routes.length;i++){//循环权限
    // if(newRouter.options.routes[i].role){//判断权限
    //   console.log(newRouter.options.routes[i].role.includes(Number(getCookies('Admin-id'))),getCookies('Admin-id'))
    //   if(newRouter.options.routes[i].role.includes(Number(getCookies('Admin-id')))){
    //     routerL.push(newRouter.options.routes[i])
    //   }
    // }else{
      routerL.push(newRouter.options.routes[i])
    // }
   
  }
  newRouter.options.routes=routerL
  router.matcher = newRouter.matcher // reset router
}
export default router
