var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',[_vm._t("default",function(){return [_c('el-button',{attrs:{"size":"small","el-button":"","type":"primary","icon":"el-icon-upload","disabled":_vm.disabled},on:{"click":function($event){return _vm.upload($event)}}},[_vm._v("点击上传")])]}),(_vm.upflag['file'] != null&&_vm.showPercent)?_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center"}},[_c('el-progress',{staticStyle:{"width":"100%"},attrs:{"percentage":_vm.percentData['file']}})],1):_vm._e()],2),_c('el-upload',{directives:[{name:"show",rawName:"v-show",value:(_vm.upflag['file']),expression:"upflag['file']"}],ref:"uploadfile",staticClass:"upload-demo",attrs:{"show-file-list":_vm.showFileList,"action":"","multiple":"","accept":_vm.accept,"auto-upload":false,"on-change":(file) => {
          return _vm.changeFile(file);
        },"limit":_vm.limit,"on-exceed":(files, fileList) => {
          return _vm.handleExceed(files, fileList);
        },"before-remove":(files, fileList) => {
          return _vm.handleRmove(files, fileList);
        },"file-list":_vm.form['filelist']}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }