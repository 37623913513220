import request from '@/utils/request'
// 获取项目列表
export function bigFileUpload(data,config) {
   
    return request({
        url: "/bigFileUpload",
        headers: config.headers,
        method: 'post',
        data
    })
}