var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"carBox"},[_vm._l((_vm.bancarList),function(item,index){return _c('div',{key:index,staticClass:"Box",style:({
      '--py':
        index == _vm.num
          ? `translate(-50%, -50%)`
          : `translate(-50%, -50%) translateX(${(index - _vm.num) * 105}%)`,
      '--tm': index == _vm.num ? 1 : 0.6,
    }),on:{"click":function($event){return _vm.luboFun(index)}}},[_c('div',{staticClass:"lunboCar row p-3"},[_c('div',{staticClass:"ImageBox col-lg-3 col-md-3 p-0"},[_c('img',{staticClass:"w-100",attrs:{"src":item.pic_name}})]),_c('div',{staticClass:"Content col-lg-8 col-md-8 ms-auto p-0 pe-3"},[_c('div',{staticClass:"title mb-3"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"desige mb-5"},[_vm._v(" "+_vm._s(item.zhaiyao)+" ")]),(index == _vm.num)?_c('div',{staticClass:"button"},[_c('el-button',{staticClass:"leftbutton",attrs:{"type":"text"},on:{"click":function($event){return _vm.jump(item)}}},[_c('span',[_vm._v(" 阅读文章 "),_c('svg-icon',{staticClass:"leftbutton-icon",attrs:{"icon-class":"leftbutton"}})],1)])],1):_vm._e()])])])}),_c('ul',_vm._l((_vm.bancarList),function(item,index){return _c('li',{key:index,class:{ yuandian: index == _vm.num },on:{"click":function($event){return _vm.luboFun(index)}}})}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }