<template>
  <div>
    <!-- <myVideo ref="myVideo" poster="https://7up.pics/images/2024/01/23/101sh.png" attachmentLink=""></myVideo> -->
    <!-- <div class="suojin">
      <div class="suojinlistMenu">
        <i class="el-icon-menu" @click="suojinList = !suojinList"></i>
      </div> -->
      <!-- <div class="suojinlist" v-show="suojinList && uploadList.length > 0">
        <div v-for="(item, index) in uploadList" :key="'pic' + index">
          <template v-if="item.name != ''">
            <span class="uploadName">{{ item.name }}</span>
            <span class="uploadJindu">{{ item.jindu }}</span>
            <i
              class="el-icon-circle-close delUpload"
              @click="delwai(index)"
            ></i>
          </template>
        </div>
      </div>
      <div v-if="suojinList && uploadList.length == 0">没有上传文件</div>-->
    <!-- </div>  -->
    <div v-for="(item, index) in uploadList" :key="index" v-show="false">
      <big-file
        :ref="'bigFile' + index"
        :showFileList="true"
        :accept="item.accept"
        :formData="bigfileFormData(item.type)"
        @changeFile="changeFile(arguments, index)"
        @upload="upload($event, index)"
        @uploadEvent="uploadEvent(arguments, index)"
        @uploadSpeed="uploadSpeed(arguments, index)"
      >
        <!-- <template v-if="ruleForm.pic_name != ''">
                <div @click="delUpload" class="delUploadBox">
                  <span>{{ ruleForm.pic_name }}</span
                  ><i class="el-icon-circle-close delUpload"></i>
                </div>
              </template> -->
      </big-file>
    </div>
    <div id="myeditTiptap" ref="editor">
      <div class="editor"></div>
    </div>
    <el-dialog
      title="修改视频样式"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form label-width="80px" :model="videoFrom">
        <el-form-item label="视频尺寸">
          <el-input v-model="videoFrom.videoSize" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="视频位置">
          <el-checkbox-group v-model="videoFrom.videoAlign" size="medium">
            <el-checkbox-button label="left" @change="videoAlign('left')"
              ><svg-icon icon-class="leftAglin"
            /></el-checkbox-button>
            <el-checkbox-button label="center" @change="videoAlign('center')"
              ><svg-icon icon-class="centerAglin"
            /></el-checkbox-button>
            <!-- <el-checkbox-button label="right" @change="videoAlign('right')"
              ><svg-icon icon-class="rightAglin"
            /></el-checkbox-button> -->
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoClose">取 消</el-button>
        <el-button type="primary" @click="videoUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import { delFuwuqiPic } from "@/api/adminHoutai";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
const titleConfig = {
  "ql-bold": "加粗",
  "ql-color": "颜色",
  "ql-font": "字体",
  "ql-code": "插入代码",
  "ql-italic": "斜体",
  "ql-link": "添加链接",
  "ql-background": "颜色",
  "ql-size": "字体大小",
  "ql-strike": "删除线",
  "ql-script": "上标/下标",
  "ql-underline": "下划线",
  "ql-blockquote": "引用",
  "ql-header": "标题",
  "ql-indent": "缩进",
  "ql-list": "列表",
  "ql-align": "文本对齐",
  "ql-direction": "文本方向",
  "ql-code-block": "代码块",
  "ql-formula": "公式",
  "ql-image": "图片",
  "ql-video": "视频",
  "ql-clean": "清除字体样式",
  "ql-upload": "文件",
  "ql-table": "插入表格",
  "ql-lineheight": "行高",
  "ql-videoSize": "视频大小",
};
import { lineHeightStyle } from "./lineHeight";
let fontFamily = ["宋体", "黑体", "微软雅黑", "楷体", "仿宋", "Arial", "苹方"];
Quill.imports["attributors/style/font"].whitelist = fontFamily;
Quill.register(Quill.imports["attributors/style/font"]);
// 自定义文字大小
let fontSize = ["10px", "12px", "14px", "16px", "20px", "24px", "36px"];
Quill.imports["attributors/style/size"].whitelist = fontSize;
Quill.register(Quill.imports["attributors/style/size"]);

Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
// 对齐方式样式都改成style方式，而不是class
let Align = Quill.import("attributors/style/align");
Align.whitelist = ["right", "center", "justify"];
Quill.register(Align, true);
import Video from "./quillVideo";
Quill.register({ "formats/video": Video }, true);
import image from "./quillImage";
Quill.register({ "formats/image": image }, true);
export default {
  name: "quillEdit",
  props: {
    //传值
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      videoFrom: {
        videoSize: "",
        videoAlign: [],
      },

      suojinList: false,
      uploadList: [],
      quill: null,
      options: {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              //   [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{'direction': 'rtl'}],                         // 文本方向
              [
                {
                  size: [
                    "10px",
                    "12px",
                    "14px",
                    "16px",
                    "20px",
                    "24px",
                    "36px",
                  ],
                },
              ], // 字体大小
              [
                {
                  font: [
                    "宋体",
                    "黑体",
                    "微软雅黑",
                    "楷体",
                    "仿宋",
                    "Arial",
                    "苹方",
                  ],
                },
              ], // 字体
              [{ lineheight: ["initial", "1", "1.5", "1.75", "2", "3", "4"] }], // 行高
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["link", "image", "video", "videoSize"], // 链接、图片、视频
              [{ table: "TD" }],
            ],
            handlers: {
              lineheight: (value) => {
                if (value) {
                  this.quill.format("lineHeight", value);
                } else {
                  //console.log(value);
                }
              },
              videoSize: (value) => {
                let range = this.quill.getSelection(true);
                let delta = this.quill.getContents(range.index, range.length); // 获取选中的内容
                //console.log(delta, range);
                var newdelta = delta.map((item) => {
                  if (typeof item.insert == "string") {
                    item.insert = item.insert.replace("\n", " ");
                  }
                  return item;
                });
                var arr = delta
                  .map((item) => {
                    if (typeof item.insert == "string") {
                      return "";
                    } else {
                      if (item.insert.video) {
                        return item;
                      } else {
                        return "";
                      }
                    }
                  })
                  .filter(
                    (item) => item !== "" && item !== null && item !== undefined
                  );
                //console.log(newdelta);
                if (arr.length == 0) {
                  this.$alert("没有选择视频不能修改", "提示", {
                    confirmButtonText: "确定",
                  });
                } else {
                  console.log(arr[0]);
                  this.videoFrom.videoSize = arr[0].attributes.width.replace(
                    "px",
                    ""
                  );
                  if (arr[0].attributes.style.indexOf("display: inline; float: left; margin: 0px 1em 1em 0px;")!=-1) {
                    this.videoFrom.videoAlign = ["left"];
                  }
                  if (arr[0].attributes.style.indexOf("display: block; margin: auto;")!=-1) {
                    this.videoFrom.videoAlign = ["center"];
                  }
                  if (arr[0].attributes.style.indexOf("display: inline; margin: 0px 0px 1em 1em; float: right;")!=-1) {
                    this.videoFrom.videoAlign = ["right"];
                  }

                  this.dialogVisible = true;
                  return;
                }
                // if(delta)
                // const size = prompt('请输入视频尺寸:', '300');
                //     if (size) {
                //         handleVideoResize(quill, size);
                //     }
              },
              table: function (val) {
                this.quill.getModule("better-table").insertTable(3, 3);
              },
              image: (e) => {
                this.uploadList = this.uploadList.filter(
                  (item) => item.name != ""
                );
                this.uploadList.push({
                  name: "",
                  jindu: 0,
                  accept: ".png,.jpg,.jpeg,.svg",
                  type: "image",
                });
                this.$nextTick(() => {
                  this.$refs[
                    "bigFile" + (this.uploadList.length - 1)
                  ][0].upload();
                });
              },
              video: () => {
                this.uploadList = this.uploadList.filter(
                  (item) => item.name != ""
                );
                this.uploadList.push({
                  name: "",
                  jindu: 0,
                  accept: ".mp4,.avi,.MP4",
                  type: "video",
                });
                this.$nextTick(() => {
                  this.$refs[
                    "bigFile" + (this.uploadList.length - 1)
                  ][0].upload();
                });
              },
            },
          },
          table: false,
          "better-table": {
            // 表格设置
            operationMenu: {
              items: {
                // 鼠标右键菜单设置,如将某一项设置false则右键菜单不会显示 如insertColumnRight: false
                insertColumnRight: { text: "右边插入一列" },
                insertColumnLeft: { text: "左边插入一列" },
                insertRowUp: { text: "上边插入一行" },
                insertRowDown: { text: "下边插入一行" },
                mergeCells: { text: "合并单元格" },
                unmergeCells: { text: "拆分单元格" },
                deleteColumn: { text: "删除列" },
                deleteRow: { text: "删除行" },
                deleteTable: { text: "删除表格" },
              },
              background: {
                color: "#333",
              },
              color: {
                colors: ["green", "red", "yellow", "blue", "white"],
                text: "背景颜色:",
              },
            },
          },
          // 更改插入的图片大小
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },

        readOnly: this.disabled, //是否只读
        placeholder: "",
      },
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    bigfileFormData() {
      return function (flag) {
        var json = {
          pic_path: flag == "image" ? "pic" : "video",
          flag: flag == "image" ? "guanwang_pic" : "yun_video",
        };
        return json;
      };
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    //console.log("quillEdit");
    const dom = this.$el.querySelector(".editor");
    this.quill = new Quill(dom, this.options);
    // console.log(this.quill)
    const toolbar = this.quill.getModule("toolbar");
    const videoSizeButton = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    videoSizeButton.innerHTML =
      '<path d="M880.288 232.48L560.192 45.12a95.648 95.648 0 0 0-96.64 0L143.68 232.48A96.64 96.64 0 0 0 96 315.904v397.664c0 34.784 18.624 66.88 48.736 84l320 181.92a95.52 95.52 0 0 0 94.496 0l320-181.92A96.576 96.576 0 0 0 928 713.568V315.904a96.64 96.64 0 0 0-47.712-83.424zM864 713.568c0 11.584-6.208 22.304-16.256 28l-320 181.92a31.776 31.776 0 0 1-31.488 0l-320-181.92A32.192 32.192 0 0 1 160 713.568V315.904c0-11.456 6.048-22.048 15.904-27.808l319.872-187.36a31.84 31.84 0 0 1 32.192 0l320.128 187.392c9.856 5.728 15.904 16.32 15.904 27.776v397.664z" p-id="4282"></path><path d="M512 320a192 192 0 1 0 0 384 192 192 0 0 0 0-384z m0 320a128 128 0 1 1 0-256 128 128 0 0 1 0 256z" p-id="4283"></path>'; // 使用Font Awesome图标
    videoSizeButton.setAttribute("viewBox", "0 0 1024 1024");
    const videoContainer = this.$refs.editor.querySelector(".ql-videoSize");
    if (videoContainer) {
      videoContainer.appendChild(videoSizeButton);
    }
    // this.quill.setContents(this.value) //detla格式数据
    // this.quill.setText(this.value) //纯文本
    this.quill.clipboard.dangerouslyPasteHTML(0, this.value); // html格式数据
    var that = this;
    this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      // console.log(node, delta, "777");
      // if(delta.ops.image)
      var arr = [];
      delta.ops.map((op) => {
        //console.log(op, "555");
         var regex = /^data:image\/.*;base64,/i;
        if (typeof op.insert != "string" && op.insert && regex.test(op.insert.image)) {
          //console.log(op.insert.image);
          arr.push({
            insert: { image: "#" },
          });
        } else {
        arr.push({
          insert: op.insert,
        });
        }
      });
      delta.ops = arr;
      return delta;
    });
    this.quill.root.addEventListener(
      "paste",
      (e) => {
        //console.log(e.clipboardData);
        if (
          e.clipboardData &&
          e.clipboardData.files &&
          e.clipboardData.files.length
        ) {
          e.preventDefault(); // 阻止默认行为即不让剪贴板内容在div中显示出来
          // 获取图片内容
          const files = e.clipboardData.files;
          this.uploadList.push({
            name: files[0].name,
            jindu: 0,
            accept: ".png,.jpg,.jpeg,.svg",
            type: "image",
          });
          var file = { raw: files[0], name: files[0].name };
          that.$nextTick(() => {
            that.$refs["bigFile" + (this.uploadList.length - 1)][0].changeFile(
              file,
              true
            );
          });
          //console.log(files);
          // uploadImg(files)
        } else {
          // console.log("粘贴内容不是图片");
        }
      },
      true
    );

    this.quill.on("text-change", (delta, oldDelta, source) => {
      console.log(delta, oldDelta, source, "7878");
      if (source === "api") {
        const images = document.querySelectorAll("img");
        images.forEach((img) => {
          console.log(img);
          // 其他需要的样式设置
        });
      }
      // //console.log(this.quill.getContents())//detla格式数据
      //console.log(this.quill.getContents()); //detla格式数据
      // this.$emit('contentData', this.quill.getContents())
      // console.log(this.quill.root.innerHTML, "4555"); //html格式数据
      // console.log(this.quill.getSemanticHTML()); //html格式数据
      this.gaibian();
      this.$emit("update:value", this.quill.getSemanticHTML());
    });
    this.quill.on("selection-change", (range, oldRange, source) => {
      let e = document.querySelector(".ql-tooltip,.ql-editing");
      if (e) {
        //console.log(e.style.left);
        let left = e.style.left;
        if (left.indexOf("-") === 0) {
          e.style.left = 0;
        }
      }
    });
    window.addEventListener("mouseup", (event) => {
      setTimeout(() => {
        let e = document.querySelector(".ql-tooltip,.ql-editing");
        if (e) {
          // //console.log(e.style.left);
          let left = e.style.left;
          if (left.indexOf("-") === 0) {
            e.style.left = 0;
          }
        }
      }, 0);
    });
    this.gaibian();
    var imglength = document.querySelectorAll(".ql-editor img").length;
    console.log(imglength);
    var videolength = document.querySelectorAll(
      ".ql-editor #videoPlayer source"
    ).length;
    if (imglength) {
      var img = document.querySelectorAll(".ql-editor img");
      img.forEach((element) => {
        if (element.parentNode.className != "vjs-poster") {
          var src = element.src.split("/");
          this.uploadList.push({
            name: decodeURI(src[src.length - 1]),
            jindu: 100,
            accept: ".png,.jpg,.jpeg,.svg",
            type: "image",
          });
        }
      });
    }
    if (videolength) {
      var video = document.querySelectorAll(".ql-editor #videoPlayer source");
      video.forEach((element) => {
        //console.log(element);
        element.src = decodeURIComponent(element.src);
        var src = element.src.split("/");
        this.uploadList.push({
          name: decodeURI(src[src.length - 1]),
          jindu: 100,
          accept: ".mp4,.avi",
          type: "video",
        });
      });
    }
    //console.log(videolength);
    this.addQuillTitle();
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    videoAlign(e) {
      if (this.videoFrom.videoAlign.length > 1) {
        this.videoFrom.videoAlign = [
          this.videoFrom.videoAlign[this.videoFrom.videoAlign.length - 1],
        ];
      }

      // this.videoFrom.videoAlign=
    },
    videoClose() {
      this.videoFrom={
        videoSize:"",
        videoAlign:[]
      }
      this.dialogVisible = false;
    },
    videoUpdate() {
      let range = this.quill.getSelection(true);
      let delta = this.quill.getContents(range.index, range.length); // 获取选中的内容
      //console.log(delta, range);
      var newdelta = delta.map((item) => {
        if (typeof item.insert == "string") {
          item.insert = item.insert.replace("\n", " ");
        }
        return item;
      });
      var arr = delta
        .map((item) => {
          if (typeof item.insert == "string") {
            return "";
          } else {
            if (item.insert.video) {
              return item;
            } else {
              return "";
            }
          }
        })
        .filter((item) => item !== "" && item !== null && item !== undefined);
      var that = this;
      this.quill.root.querySelectorAll("*").forEach(function (node) {
        //console.log(node.src, node.className, node, "node");
        var element = node;
        //console.log(element.getAttribute("src"));
        if (arr[0].insert.video.url == element.getAttribute("src")&&element.className=="ql-video") {
          var src = element.getAttribute("src"),
            poster = element.getAttribute("poster"),
            style = `width:${that.videoFrom.videoSize}px;`,
            width = that.videoFrom.videoSize + "px";
          if (that.videoFrom.videoAlign.length != 0) {
            var align = that.videoFrom.videoAlign.join(",");
            switch (align) {
              case "left":
                style +=
                  "display: inline; float: left; margin: 0px 1em 1em 0px;";
                break;
              case "center":
                style += "display: block; margin: auto;";
                break;
              case "right":
                style +=
                  "display: inline; margin: 0px 0px 1em 1em; float: right;";
                break;
            }
          }
          //console.log(element.innerHTML);
          element.outerHTML = `<div class="ql-video" controls="" poster="${poster}" src="${src}" width="${width}" style="${style}"><video id="videoPlayer"  class="video-js vjs-default-skin" controls playsinline width="${width}" poster="${poster}"><source src="${src}" type="application/x-mpegURL"></video></div>`;
        }
        // if (node.tagName.toLowerCase() === "video") {
        //   // 设置视频尺寸
        //   node.style.width = "640px"; // 新宽度
        //   node.style.height = "360px"; // 新高度
        // }
      });
      this.videoClose();
    },
    extractImageDataFromRtf(rtfData) {
      if (!rtfData) {
        return [];
      }
      const regexPictureHeader =
        /{\\pict[\s\S]+?({\\\*\\blipuid\s?[\da-fA-F]+)[\s}]*/;
      const regexPicture = new RegExp(
        "(?:(" + regexPictureHeader.source + "))([\\da-fA-F\\s]+)\\}",
        "g"
      );
      const images = rtfData.match(regexPicture);
      const result = [];
      if (images) {
        for (const image of images) {
          let imageType = false;

          if (image.includes("\\pngblip")) {
            imageType = "image/png";
          } else if (image.includes("\\jpegblip")) {
            imageType = "image/jpeg";
          }

          if (imageType) {
            result.push({
              hex: image
                .replace(regexPictureHeader, "")
                .replace(/[^\da-fA-F]/g, ""),
              type: imageType,
            });
          }
        }
      }
      return result;
    },
    gaibian() {
      var imglength = document.querySelectorAll(".ql-editor img").length;
      var videolength = document.querySelectorAll(
        ".ql-editor #videoPlayer source"
      ).length;
      var arr = [];
      if (imglength) {
        var img = document.querySelectorAll(".ql-editor img");
        img.forEach((element) => {
          if (element.parentNode.className != "vjs-poster") {
            var src = element.src.split("/");
            arr.push({
              name: decodeURI(src[src.length - 1]),
              jindu: 100,
              accept: ".png,.jpg,.jpeg,.svg",
              type: "image",
            });
          }
        });
      }
      //console.log(videolength);
      if (videolength) {
        var video = document.querySelectorAll(".ql-editor #videoPlayer source");
        video.forEach((element) => {
          //console.log(element);
          var src = element.src.split("/");
          arr.push({
            name: decodeURI(src[src.length - 1]),
            jindu: 100,
            accept: ".mp4,.avi",
            type: "video",
          });
        });
      }
      arr = arr.map((item) => item.name);
      //console.log(arr);
      var arr1 = this.uploadList.map((item) => item.name);
      var sa = new Set(arr);
      var sb = new Set(arr1);
      // 差集
      let minus = arr1.filter((x) => !sa.has(x));
      //console.log(minus);
      this.uploadList.map((item, index) => {
        if (minus.includes(item.name)) {
          // this.delUpload(index);
        }
      });
      var videoPlayer = document.querySelectorAll("#videoPlayer").length;
        if (videoPlayer) {
          var img = document.querySelectorAll("#videoPlayer");
          img.forEach((element) => {
            videojs(
              element,
              {
                playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                aspectRatio: "16:9",
                notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                autoplay: false, // 设置自动播放
                muted: false, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
                preload: "auto", // 预加载
                controls: true, // 显示播放的控件
              } /* function() {
        // Player (this) is initialized and ready.
      } */
            );
          });
        }
    },
    addQuillTitle() {
      const oToolBar = document.querySelector(".ql-toolbar");
      const aButton = oToolBar.querySelectorAll("button");
      const aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(function (item) {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1"
            ? (item.title = "向右缩进")
            : (item.title = "向左缩进");
        } else {
          item.title = titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(function (item) {
        item.parentNode.title = titleConfig[item.classList[0]];
      });
    },
    getContentData() {
      return this.quill.getContents();
    },

    //----------大文件上传
    /**
     *  执行大文件上传
     */
    upload(event, index) {
      /* 执行大文件上传 */
      // this.$refs.bigFile.upload(event);

      this.$refs["bigFile" + index][0].upload(event, true); //必须这样传回给子组件
    },
    /** 大文件上传的进度 */
    uploadSpeed(val, index1) {
      /* 大文件上传的进度 */
      //console.log(val);

      this.uploadList[index1].jindu = val[0];
    },
    /* 文件改变 */
    changeFile(val, index1) {
      //console.log(val);

      this.uploadList[index1].name = val[0].name;
      this.$refs["bigFile" + index1][0].changeFile(val[0], true); //必须这样传回给子组件
    },
    /** 大文件上传成功返回内容 */
    uploadEvent(val, index) {
      /* 大文件上传成功返回内容 */
      // val[0] //上传时名字
      // val[1] //成功后返回的所有内容
      //console.log(val, index);

      this.uploadList[index].name = val[0];
      let quill = this.quill; //获取富文本编辑器dom对象

      let length = quill.getSelection(); //光标位置
      length = length ? length.index : 0;
      if (this.uploadList[index].type == "image") {
        let imgUrl = val[1].file_path; // 拼接图片地址
        quill.insertEmbed(length, "image", imgUrl); // 插入图片  图片地址
      } else if (this.uploadList[index].type == "video") {
        let videoLink = val[1].file_path;
        let file_path_cover = val[1].file_path_cover;
        var str = val[0].split(".");
        str[str.length - 1] = "m3u8";
        this.uploadList[index].name = str.join(".");
        quill.insertEmbed(length, "video", {
          url: videoLink,
          poster: file_path_cover,
          width: "300px",
          style:"width:300px;"
        });
      }

      quill.setSelection(length + 1); // 调整光标到最后
    },
    delwai(index) {
      //console.log();
      const name = this.uploadList[index].name;
      const delta = this.quill.getContents();
      //console.log(delta);
      // 查找Delta中的图片
      let index1 = 0;
      let index2 = 0;
      for (var i = 0; i < delta.ops.length; i++) {
        if (delta.ops[i].insert.image || delta.ops[i].insert.video) {
          var url = "";
          if (delta.ops[i].insert.image) {
            url = delta.ops[i].insert.image.split("/");
            url = url[url.length - 1];
          }
          if (delta.ops[i].insert.video) {
            url = delta.ops[i].insert.video.url.split("/");
            url = url[url.length - 1];
          }
          if (url == name) {
            break;
          } else {
            index2 += 1;
          }
        } else {
          index2 += delta.ops[i].insert.length;
        }
      }
      // while (index1 < delta.ops.length && !delta.ops[index1].insert.image) {
      //   if(delta.ops[index1].insert){
      //     index2+=delta.ops[index1].insert.length
      //   }
      //   index1++;
      // }
      //console.log(index2);

      // 如果找到了图片，删除它
      // if (index1 < delta.ops.length) {
      // this.quill.deleteText(index2, 1);
      // }
      // 如果找到图片，则删除它
      // if (imageIndex !== -1) {
      //   // 创建一个新的Delta来删除图片

      //   // 应用新的Delta到编辑器
      this.quill.updateContents([{ retain: index2 }, { delete: 1 }]);
      // }
    },
    delUpload(index) {
      return
      var pic_name = this.uploadList[index].name;

      var data = {};
      if (this.uploadList[index].type == "video") {
        data.flag = "video";
        data.video_name = pic_name;
      } else {
        data = {
          pic_name: pic_name,
        };
      }
      delFuwuqiPic(data)
        .then((res) => {
          //console.log(res);
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.uploadList.splice(index, 1);
          }
        })
        .catch((err) => {
          // 报错
          //console.log(err);
        }); // this.ruleForm.pic_name = "";
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.suojin {
  position: absolute;
  right: 15px;
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
  background: #909399;
  color: #fff;
  .suojinlistMenu {
    text-align: right;
    i {
      cursor: pointer;
      border: 1px solid;
      padding: 5px;
      border-radius: 5px;
    }
  }
  .suojinlist {
    margin-top: 20px;
    .uploadName {
      margin-left: 10px;
    }
    .uploadJindu {
      margin-left: 10px;
    }
    .delUpload {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
#myeditTiptap {
  ::v-deep .ql-container {
    height: 55vh;
  }
  ::v-deep .ql-snow {
    .ql-picker {
      height: 40px;
    }
    .ql-picker.ql-size {
      width: 70px; // 菜单栏占比宽度
    }
    .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
      content: "10px";
    }
    .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
      content: "12px";
    }
    .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
      content: "14px";
    }
    .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
      content: "16px";
    }
    .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
      content: "20px";
    }
    .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
      content: "24px";
    }
    .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
      content: "36px";
    }

    /*
      字体
    */
    .ql-picker.ql-font {
      width: 80px; // 菜单栏占比宽度
    }
    .ql-picker.ql-font .ql-picker-label[data-value="宋体"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="宋体"]::before {
      content: "宋体";
    }
    .ql-picker.ql-font .ql-picker-label[data-value="黑体"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="黑体"]::before {
      content: "黑体";
    }
    .ql-picker.ql-font .ql-picker-label[data-value="微软雅黑"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="微软雅黑"]::before {
      content: "微软雅黑";
    }
    .ql-picker.ql-font .ql-picker-label[data-value="楷体"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="楷体"]::before {
      content: "楷体";
    }
    .ql-picker.ql-font .ql-picker-label[data-value="仿宋"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="仿宋"]::before {
      content: "仿宋";
    }
    .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
      content: "Arial";
    }
    .ql-picker.ql-font .ql-picker-label[data-value="苹方"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="苹方"]::before {
      content: "苹方";
    }

    /*
      标题  
    */
    .ql-picker.ql-header {
      width: 80px; // 菜单栏占比宽度
    }
    .ql-picker.ql-header .ql-picker-label::before,
    .ql-picker.ql-header .ql-picker-item::before {
      content: "文本" !important;
    }
    .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: "标题1" !important;
    }
    .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: "标题2" !important;
    }
    .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: "标题3" !important;
    }
    .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: "标题4" !important;
    }
    .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
      content: "标题5" !important;
    }
    .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
      content: "标题6" !important;
    }
    //配置编辑器行高
    .ql-picker.ql-lineheight {
      width: 70px; // 菜单栏占比宽度
    }
    .ql-picker.ql-lineheight .ql-picker-label::before {
      content: "行高";
    }

    .ql-picker.ql-lineheight .ql-picker-item[data-value="initial"]::before {
      content: "默认";
    }
    .ql-picker.ql-lineheight .ql-picker-item[data-value="1"]::before {
      content: "1";
    }

    .ql-picker.ql-lineheight .ql-picker-item[data-value="1.5"]::before {
      content: "1.5";
    }

    .ql-picker.ql-lineheight .ql-picker-item[data-value="1.75"]::before {
      content: "1.75";
    }
    .ql-picker.ql-lineheight .ql-picker-item[data-value="2"]::before {
      content: "2";
    }
    .ql-picker.ql-lineheight .ql-picker-item[data-value="3"]::before {
      content: "3";
    }
    .ql-picker.ql-lineheight .ql-picker-item[data-value="4"]::before {
      content: "4";
    }
    .ql-picker.ql-lineheight .ql-picker-item[data-value="5"]::before {
      content: "5";
    }
  }
  /*
      文字大小
    */
}
</style>