const KEY = 'VUEX_STORE'
var _beforeUnload_time=null
export default function (store) {
    // console.log(store)
    //保存仓库数据到本地
    window.addEventListener("beforeunload", () => {
        // _beforeUnload_time=new Date().getTime();
        // console.log(event)
        // event = event | window.event;
        // if(window.confirm('是否要离开本页面吗')){
        //     localStorage.setItem(KEY, JSON.stringify(store.state))
        // }else{
            var json={}
            for(var key in store.state){
                console.log(key)
                if(key!='log'){
                json[key]=store.state[key]

                }   
            }
            window.localStorage.setItem(KEY, JSON.stringify(json))
        // }
        
    })
    //恢复仓库数据
    try {
        const localState = localStorage.getItem(KEY)
        if (localState) {
            store.replaceState(Object.assign({}, store.state, JSON.parse(localState)))
            localStorage.removeItem(KEY);
        }
    }
    catch{
        console.log('本地存储数据异常')
    }
    
}