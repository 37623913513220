function setRem() {
    const screenWidth = 192
    const scale = screenWidth / 1.92
    const scale1 = screenWidth / 18
    const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
    const htmlDowm = document.getElementsByTagName('html')[0]
    const bodyDowm = document.getElementsByTagName('body')[0]
    htmlDowm.style.fontSize = htmlWidth / scale + 'px'
    // bodyDowm.style.fontSize = htmlWidth / scale1 + 'px'
    // bodyDowm.style = ''
}
setRem()
window.onresize = function () {
    setRem()
}