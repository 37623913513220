/** 
 * getCookies(name) //获取cookies
 * setCookies(name,value) //添加cookies name：cookies名，value：cookies值
 * removeCookies(name) //删除cookies name非必传，为空就是删除所有cookies，有名字就删除指定cookies
  */
import Cookies from 'js-cookie' //cookies
/**
 * @param {string} name
 * @returns {string}
 */
export function getCookies(name) { //获取cookies
  return Cookies.get(name)
  }
/**
 * @param {string} name
 * @param {string,int} value
 * @param {int} time
 */
export function setCookies(name,value,time=null) { //添加cookies
  var expirationDate = new Date();
  expirationDate.setHours(24); // 将小时数设置为24（表示第二天）
  expirationDate.setMinutes(0); // 将分钟数设置为0（表示第二天）
  expirationDate.setSeconds(0); // 将分钟数设置为0（表示第二天）
  if(time!==null&&time!==""&&time!==undefined){
    expirationDate=time
  }
  Cookies.set(name, value,{ expires: expirationDate })
  }
/**
 * @param {string} name
 * @returns {string}
 */
export function removeCookies(name) { //删除cookies
    if(name===null||name==""||name===undefined){
      Cookies.remove()
    }else{
      Cookies.remove(name)
    }
  }