<template>
  <section class="carBox">
    <!-- d-flex align-items-center -->
    <div
      v-for="(item, index) in bancarList"
      :key="index"
      class="Box"
      :style="{
        '--py':
          index == num
            ? `translate(-50%, -50%)`
            : `translate(-50%, -50%) translateX(${(index - num) * 105}%)`,
        '--tm': index == num ? 1 : 0.6,
      }"
      @click="luboFun(index)"
    >
      <div class="lunboCar row p-3">
        <div class="ImageBox col-lg-3 col-md-3 p-0">
          <img :src="item.pic_name" class="w-100" />
        </div>
        <div class="Content col-lg-8 col-md-8 ms-auto p-0 pe-3">
          <div class="title mb-3">
            {{ item.name }}
          </div>
          <div class="desige mb-5">
            {{ item.zhaiyao }}
          </div>
          <div class="button" v-if="index == num">
            <el-button type="text" class="leftbutton" @click="jump(item)">
              <span>
                阅读文章
                <svg-icon icon-class="leftbutton" class="leftbutton-icon" />
              </span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <ul>
      <li
        :class="{ yuandian: index == num }"
        v-for="(item, index) in bancarList"
        :key="index"
        @click="luboFun(index)"
      ></li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "lunboCarcom",
  props: {
    //传值
    bancarList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      num: 0,
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("luobocom");
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    luboFun(item) {
      console.log(item);
      this.num = item;
    },
    jump(item){
      if (item.tiaozhuan) {
        const pattern = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;
        if (!pattern.test(item.tiaozhuan)) {
          this.$router.push({ path: item.tiaozhuan });
        } else {
          window.open(item.tiaozhuan);
        }
      }
    }
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
$design-width: 192;
@function pxttrem($px) {
  @return calc($px/$design-width) * 10 + rem;
}
.carBox {
  position: relative;
  height: pxttrem(518);
  .Box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: var(--py);
    opacity: var(--tm);
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  ul {
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    .yuandian {
      &::after {
        opacity: 1;
      }
    }
    li {
      cursor: pointer;
      display: inline-block;

      padding: pxttrem(10);
      margin-right: pxttrem(20); /* 与文本的距离 */

      text-align: center;

      box-sizing: border-box;
      &::after {
        content: "";
        display: block;
        width: pxttrem(6);
        height: pxttrem(6);
        border-radius: 50%;
        background: #ffffff;
        opacity: 0.5;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}
.lunboCar {
  position: relative;
  height: fit-content;
  background: rgba(69, 166, 255, 0.3);
  box-shadow: pxttrem(23) pxttrem(21) pxttrem(63) 0px rgba(0, 4, 96, 0.2);
  border-radius: 12px;
  .ImageBox {
    // position: absolute;
    top: pxttrem(39);
    left: pxttrem(53);
    img {
      transition: transform 0.3s ease;
      height: pxttrem(315.98);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .Content {
    position: relative;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: pxttrem(17);
    color: #ffffff;
    line-height: pxttrem(36);
    text-align: left;
    font-style: normal;
    text-transform: none;
    align-items: center;
    display: grid;
    .title {
      font-weight: 600;
      font-size: pxttrem(24);
      color: #ffffff;
      line-height: pxttrem(40);
    }
    .desige {
      opacity: 0.8;
      line-height: 1.5;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .button {
      text-align: right;
      .leftbutton {
        color: #fff;
        .leftbutton-icon {
          width: 1.5em;
          color: #1ae25e;
          opacity: 0.7;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .lunboCar {
    position: relative;
    width: pxttrem(800);
    height: pxttrem(270);
    padding: pxttrem(10) pxttrem(30) 0 0;
    .ImageBox {
      position: absolute;
      top: 10%;
      left: 5%;
      width: 18%;
    }
    .Content {
      margin-left: 30% !important;
      width: pxttrem(500);
      .title {
        font-size: pxttrem(16);
        line-height: pxttrem(20);
        margin-bottom: pxttrem(10);
      }
      .leftbutton{
        font-size: pxttrem(14);
      }
      .Line {
        width: pxttrem(26);
        border: pxttrem(3) solid #1ae25e;
        margin-bottom: pxttrem(10);
      }
      .desige {
        font-weight: 400;
        font-size: pxttrem(14);
        line-height: pxttrem(18);
      }
    }
  }
}
</style>