<!--
* accept为限制文件格式
 * formData 必须为对象格式，属于添加大文件上传额外参数
 * upload 上传前的事件，可以做判断
 * uploadEvent 文件上传成功事件
 * uploadSpeed 文件上传的进度
 * changeFile 文件选择后，可以不写

 * 使用方法如下
 <big-file ref="bigFile" :formData="bigfileFormData" accept=".zip,.csv" @upload="upload($event)" @uploadEvent="uploadEvent($event)" @uploadSpeed="uploadSpeed($event)" @changeFile="changeFile($event)"></big-file> 
 
 * 方法调用如下
 /**
      *  文件改变时执行
      */
 changeFile(val){
      console.log(val)
      this.$refs.bigFile.changeFile(val,true)//必须这样传回给子组件
    },
    /**
      *  执行大文件上传
      */
    upload(event) {
      /* 执行大文件上传 */
      // this.$refs.bigFile.upload(event);
      if (
        this.ruleForm.entryName == "" ||
        this.ruleForm.entryName == undefined ||
        this.ruleForm.entryName == null
      ) {
        this.$alert("请先选择项目", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
        return;
      }
      var reg = /^(?!-)[a-zA-Z0-9-]*$/;
      console.log(reg.test(this.ruleForm.taskName), this.ruleForm.taskName);
      if (this.ruleForm.taskName == "" || !reg.test(this.ruleForm.taskName)) {
        this.$alert("请先填写任务名称", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
        return;
      }
      this.$refs.bigFile.upload(event,true)//必须这样传回给子组件
    },
    /** 大文件上传的进度 */
    uploadSpeed(val) {
      /* 大文件上传的进度 */
      console.log(val);
      this.uploadValue = val;
    },
    /** 大文件上传成功返回内容 */
    uploadEvent(val) {
      /* 大文件上传成功返回内容 */
      console.log(val);
      this.ruleForm.uploadFile = val;
    }, 
-->

<template>
  <div>
    <div>
      <div>
        <slot>
          <el-button
            size="small"
            el-button
            type="primary"
            icon="el-icon-upload"
            @click="upload($event)"
            :disabled="disabled"
            >点击上传</el-button
          >
        </slot>
        <div
          v-if="upflag['file'] != null&&showPercent"
          style="width: 100%; display: flex; align-items: center"
        >
          <!-- 正在上传 --><el-progress
            :percentage="percentData['file']"
            style="width: 100%"
          ></el-progress>
        </div>
      </div>

      <el-upload
        :show-file-list="showFileList"
        v-show="upflag['file']"
        class="upload-demo"
        action
        multiple
        :accept="accept"
        ref="uploadfile"
        :auto-upload="false"
        :on-change="
          (file) => {
            return changeFile(file);
          }
        "
        :limit="limit"
        :on-exceed="
          (files, fileList) => {
            return handleExceed(files, fileList);
          }
        "
        :before-remove="
          (files, fileList) => {
            return handleRmove(files, fileList);
          }
        "
        :file-list="form['filelist']"
      >
        <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
      </el-upload>
    </div>
  </div>
</template>

<script>
import { bigFileUpload } from "./uploadBid";
import { delFuwuqiPic } from "@/api/adminHoutai";
import { Loading } from 'element-ui'
export default {
  name: "bigUpload",
  props: {
    //传值
    // flag: {
    //   type: String,
    //   default: "",
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    showPercent: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: null,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loadingInstance:null,
      form: {
        filelist: [],
      },
      postFlag: true,
      upflag: {},
      percentData: {},
      timeDate: null, //上传文件的时间戳
      changeFileShow: false,
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    const parentMethods = Object.keys(this.$listeners); //有没有父组件传递过来的自定义事件
    // console.log(parentMethods,"555")
    if (!parentMethods.includes("changeFile")) {
      this.changeFileShow = true;
    }
  },
  methods: {
    // 组件的方法
    upload(evt, callback) {
      if (evt) {
        let target = evt.target;
        if (target.nodeName == "SPAN") {
          target = evt.target.parentNode;
        }
        target.blur();
      }
      if (!callback) {
        this.$emit("upload", evt);
      } else {
        this.$refs["uploadfile"].$refs["upload-inner"].handleClick();
      }
    },
    changeFile(file, callback) {
      if (this.changeFileShow && !callback) {
        callback = this.changeFileShow;
      }
      if (!callback) {
        this.$emit("changeFile", file);
      } else {
        // file.label = "上传文件";
        // let regExp = new RegExp( "[\\[\\]\\^ \\-*×――(^)$%~!＠@＃#$…&%￥—+=<>《》!！??？:：•`·、。，；,;/\'\"{}（）()‘’“”-]");

        // if(regExp.test(file.name)){
        //   this.$alert('上传文件的命名不能有特殊字符，下划线除外', '提示', {
        //     confirmButtonText: '确定',
        //     callback: action => {
        //       this.form["filelist"]= [];
        //       this.progrs = false;
        //       this.upflag = {};
        //       // this.$message({
        //       //   type: 'info',
        //       //   message: `action: ${ action }`
        //       // });
        //     }
        //   });
        //   return
        // }
        // var reg =/^[a-zA-Z0-9_.]+$/;
        // if(!reg.test(file.name)){
        //   this.$alert('上传文件的命名不能有中文', '提示', {
        //     confirmButtonText: '确定',
        //     callback: action => {
        //       this.form["filelist"]= [];
        //       this.progrs = false;
        //       this.upflag = {};
        //       // this.$message({
        //       //   type: 'info',
        //       //   message: `action: ${ action }`
        //       // });
        //     }
        //   });
        //   return
        // }
        this.form["filelist"].push(file);
        this.percentData["file"] = 0;
        if (file) {
          if (this.accept != "") {
            var arr = this.accept.split(",");
            const fileName = file.name;
            const fileType = fileName.substring(fileName.lastIndexOf("."));
            if (arr.includes(fileType)) {
              this.upflag["file"] = file.raw.name;
              this.progrs = true;
              this.uploadFile(file.raw, 0);
            } else {
              this.form["filelist"] = [];
              this.$message.error("文件格式不正确，请重新上传");
            }
          } else {
            this.upflag["file"] = file.raw.name;
            this.progrs = true;
            this.uploadFile(file.raw, 0);
          }
        } else {
          this.progrs = false;
        }
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
      this.$message({
        showClose: true,
        message: "超出上传限制！",
        type: "error",
        offset: "100",
      });
    },
    handleRmove(file, fileList) {
      this.form["filelist"] = [];
      this.progrs = false;
      this.upflag = {};
      this.$emit("handleRmove", file);
      var data = {
        pic_name: typeof file != "string" ? file.name : file,
        flag:this.formData.pic_path
      };
      delFuwuqiPic(data)
        .then((res) => {
          console.log(res);
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
          }
        })
        .catch((err) => {
          // 报错
          console.log(err);
        });
      // this.upflag["file"] = null;
    },
    uploadFile(file, skip) {
      if(!this.loadingInstance){
        this.loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      }
      var _this = this;
      var formData = new FormData(); //初始化一个FormData对象
      var blockSize = 2 * 1024 * 1024; //10M每块的大小
      var nextSize = Math.min((skip + 1) * blockSize, file.size); //读取到结束位置
      var fileData = file.slice(skip * blockSize, nextSize); //截取 部分文件 块
      //文件名添加随机值，同一个文件保持一致
      if (skip == 0) {
        _this.timeDate = new Date().getTime();
      }
      this.form["filelist"][0].name = _this.timeDate + file.name;
      // console.log(_this.timeDate + file.name);
      formData.append("file", fileData); //将 部分文件 塞入FormData
      formData.append("oldName", _this.timeDate + file.name); //文件原始名字
      formData.append("fileN", _this.timeDate + file.name + ".temp" + skip); //保存文件名git
      formData.append("index", skip); //保存当前碎片序列
      formData.append("total", Math.ceil(file.size / blockSize)); //保存总共文件序列
      if (_this.formData) {
        for (var key in _this.formData) {
          formData.append(key, _this.formData[key]);
        }
      }
      // formData.append("flag", _this.flag); // 是否为大文件上传的标志量  1为小文件上传2为大文件上传
      if (file.name == _this.upflag["file"]) {
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        };
        bigFileUpload(formData, config)
          .then((data) => {
            console.log(data);
            if (skip + 1 <= Math.ceil(file.size / blockSize)) {
              _this.percentData["file"] = Math.trunc(
                Number(((skip + 1) / Math.ceil(file.size / blockSize)) * 100)
              );
              _this.$emit("uploadSpeed", _this.percentData["file"]);
              this.$forceUpdate();
            } else {
              return;
            }
            if (file.size <= nextSize) {
              //如果上传完成，则跳出继续上传
              // _this.$message(data.msg)
              // console.log(formData)
              this.loadingInstance.close()
              this.loadingInstance=null
              _this.$emit(
                "uploadEvent",
                _this.timeDate + file.name,
                data.data
              ); /*  this.form["filelist"] */
              //   _this.$message({
              //     showClose: false,
              //     message: data.data.msg,
              //     type: "success",
              //     offset: "100",
              //   });
              //  _this.upflag=null;
              //  _this.upSize=0;
              //  _this.skip=0
              /*   this.successFlag = true;
                this.fileName = data.data.fileName
                this.rowNum = data.data.rowNum
                this.fileSize = data.data.fileSize
                this.$emit("fileTime",_this.timeDate + file.name);
                this.$emit("completeFlag",_this.successFlag); */
              return;
            }
            if (data.code == 1) {
              console.log(data);
              _this.uploadFile(file, data.index);
            } else {
              console.log(data, _this.postFlag);
              if (_this.postFlag) {
                _this.uploadFile(file, ++skip); //递归调用
              } else {
                // this.postFlag = true;
                return;
              }
            }
          })
          .catch((res) => {
            // _this.errBtn = true;
          });
      }
    },
    /* 生成文件切片 */
    createFileChunk(file, size = SIZE) {
      const fileChunkList = [];
      let cur = 0;
      while (cur < file.size) {
        fileChunkList.push({ file: file.slice(cur, cur + size) });
        cur += size;
      }
      return fileChunkList;
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.item {
  color: #66b1ff;
}
.form >>> .el-select {
  width: 100%;
}
.form >>> .el-input {
  width: 100%;
}
.form >>> .el-input-number {
  width: 100%;
}
.form >>> .el-textarea {
  width: 100%;
}
.search {
  float: right;
}
.jumpBread >>> .el-breadcrumb__inner {
  cursor: pointer;
  color: #66b1ff;
}
.upload-demo >>> .el-upload {
  display: none;
}
.labelFrom {
  font-size: 14px;
}
.form >>> .el-input--medium {
  font-size: 14px;
}
.uploadLast:last-child {
  margin-bottom: 0;
}
</style>
<style scoped>
.upload-demo {
  width: 200px;
}
.rowDown {
  width: 500px;
  display: flex;
  align-items: center;
}
</style>