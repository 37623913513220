import Quill from "quill";
import "video.js/dist/video-js.css";
import videojs from "video.js";
// 源码中是import直接倒入，这里要用Quill.import引入
const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')

const ATTRIBUTES = ['height', 'width','video',"style"]

class Video extends BlockEmbed {
    static create(value) {
        let node = super.create()
        //添加
        node.setAttribute('controls', '');
        node.setAttribute('poster', value.poster);
        node.setAttribute('src', value.url);
        node.setAttribute('width', value.width);
        node.setAttribute('style', value.style);
        node.innerHTML = `<video id="videoPlayer"  class="video-js vjs-default-skin"
    controls
    playsinline
    width="${value.width}"
    poster="${value.poster}"><source src="${value.url}" type="application/x-mpegURL">
    </video>`;
        // node.setAttribute('src', value.url)
        // node.setAttribute('poster', value.poster)
        // node.setAttribute('preload',"auto")
        // node.setAttribute('controls', true)
        // node.setAttribute('width', 300)
        // // node.setAttribute('height', value.height)
        // // node.setAttribute('loop', value.loop)
        // node.setAttribute('autoplay', true)
        // node.setAttribute('type', "application/x-mpegURL")
        // node.setAttribute('muted', false)
        return node
    }

    static formats(domNode) {
        return ATTRIBUTES.reduce((formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute)
            }
            return formats
        }, {})
    }

    static sanitize(url) {
        return Link.sanitize(url)
    }

    static value(domNode) {
        // 设置值包含宽高，为了达到自定义效果
        //宽高为空的话，就是按100%算
        // console.log(domNode)
        return {
            url: domNode.getAttribute('src'),
            poster: domNode.getAttribute('poster'),
            // controls: domNode.getAttribute('controls'),
            width: domNode.getAttribute('width'),
            style: domNode.getAttribute('style'),
            // height: domNode.getAttribute('height'),
            // autoplay: domNode.getAttribute('autoplay'),
            // loop: domNode.getAttribute('loop'),
            // muted: domNode.getAttribute('muted'),
            // type: domNode.getAttribute('type'),

        }
    }


    format(name, value) {
        // console.log(name, value, "88")
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value)
            } else {
                this.domNode.removeAttribute(name)
            }
        } else {
            super.format(name, value)
        }
    }
    html() {
        const { video } = this.value()
        console.log(video, "99")
        return `<div class="ql-video" controls="" poster="${video.poster}" src="${video.url}" width="${video.width}" style="${video.style}"><video id="videoPlayer"  class="video-js vjs-default-skin" controls playsinline width="${video.width}" poster="${video.poster}"><source src="${video.url}" type="application/x-mpegURL"></video></div>`
    }
}
Video.blotName = 'video'
Video.className = 'ql-video' // 可添加样式，看主要需要
Video.tagName = 'div' // 用video标签替换iframe

export default Video