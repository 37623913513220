import Vue from 'vue'
import Confirm from './notificationBox.vue'

let confirmStructor = Vue.extend(Confirm)   //返回一个实例创建的构造器，但实例构造器需要进行挂载到页面中
/** 
 * 使用方法 Notification({
            title: '页面更新提示',
            message: "更新版本：V" + packageJson.version + '<br>网页内容有更新，请按Ctrl+R或F5刷新页面',
            duration: 0,
            type: 'diyjinggao',
            btn:{success:{label:"更新",hidden:false},close:{label:"关闭",hidden:true}}
        }).then(() => { 
            //点击确定事件
            location.reload(true)
        }).catch(() => {
            //点击取消事件
            console.log('no')
        })
 */
let theConfirm = function (json) {
    return new Promise((res, rej) => {       //返回一个promise，进行异步操作，成功时返回，失败时返回
        let confirmDom = new confirmStructor({
            el: document.createElement('div')
        })
        console.log(confirmDom)
        //在body中动态创建一个div元素，之后此div将会替换成整个vue文件的内容
        //此时的confirmDom通俗讲就是相当于是整个组件对象，通过对象调用属性的方法来进行组件中数据的使用
        //可以通过$el属性来访问创建的组件实例
        document.body.appendChild(confirmDom.$el)

        //此时进行创建组件的逻辑处理
        confirmDom.json = json       //将需要传入的文本内容传给组件实例
        if (json.duration > 0) {
            setTimeout(() => {
                res()
                confirmDom.flag = false;
            }, json.duration);
            
        }
        confirmDom.onSuccess = () => {     //箭头函数，在（）和{}之间增加=>,且去掉function
            res()   //正确时返回的操作
            confirmDom.flag = false;
        }
        confirmDom.onError = () => {
            rej()   //失败时返回的操作
            confirmDom.flag = false;
        }
    })
}
export default theConfirm