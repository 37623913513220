/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) { //外部资源
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 获取唯一id
export function getid() {
  const r = URL.createObjectURL(new Blob());
  return r.substr(r.lastIndexOf("/") + 1, r.length - 1);
}
  // 深拷贝
export function deepCopy(obj) {
if (typeof obj !== "object" || obj === null) {
  return obj;
}

let copy;

if (Array.isArray(obj)) {
  copy = [];
  for (let i = 0; i < obj.length; i++) {
    copy[i] = deepCopy(obj[i]);
  }
} else {
  copy = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }
}

return copy;
}