import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Cookies from 'js-cookie' //cookies
Vue.prototype.$Cookies = Cookies; //全局使用cookie
import './icons' // 引入icon
/* elementui */
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'
Vue.use(Element, {
  size: 'medium', // set element-ui default size
})

Vue.config.productionTip = false


import { globalVariable } from '@/global/Variable'
Vue.prototype.$globalVariable = globalVariable; //全局变量

import * as methodCookies from "@/utils/methodCookies";
Vue.prototype.$methodCookies = methodCookies; //定义全局cookie方法

import { deepCopy } from "@/utils/validate";
Vue.prototype.$deepCopy = deepCopy; //深拷贝

import BigFile from "@/components/bigUpload";//大文件上传
Vue.component("BigFile", BigFile);

import Pagination from '@/components/Pagination'//分页
Vue.component("Pagination", Pagination);

import imageTanchuang from "@/components/imageTanchuang/index"//图片上传组件
Vue.component("imageTanchuang", imageTanchuang);
import lunbocom from "@/components/lunbocom/index"//轮播组件
Vue.component("lunbocom", lunbocom);
import lunboCarcom from "@/components/lunboCarcom/index"//轮播组件
Vue.component("lunboCarcom", lunboCarcom);
import hezuocom from "@/components/hezuocom/index"//合作伙伴组件
Vue.component("hezuocom", hezuocom);
import Bread from '@/components/Bread'//面包屑
Vue.component("Bread", Bread);
import myVideo from '@/components/videoJs/index'//面包屑
Vue.component("myVideo", myVideo);

import { v4 as uuidv4 } from 'uuid';
Vue.prototype.$uuid = uuidv4;
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
Vue.prototype.$gsap = gsap;
Vue.prototype.$ScrollTrigger = ScrollTrigger;


import QuillBetterTable from 'quill-better-table' // 可编辑的table
import 'quill-better-table/dist/quill-better-table.css'
// // import imageResize  from 'quill-image-resize-module' // 调整大小组件。

import imageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module'; // 拖动加载图片组件。

Quill.register('modules/imageResize', imageResize);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/better-table', QuillBetterTable);

// Vue.use(VueQuillEditor);
import quillEdit from '@/components/quillEdit/index'//面包屑
Vue.component("quillEdit", quillEdit);

import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap"
import 'amfe-flexible'//自适应
import '@/utils/rem.js'
// import "@/log";//引入log监听文件

// import vConsole from '@/utils/vconsole' //移动端显示console
// import consoleFu from '@/utils/consoleLog' //移动端显示console方法
import Notification from '@/components/Notification';
Vue.prototype.$Notification = Notification
import '@/styles/globalCss.scss'//全局css样式
// import '@/assets/css/home.css'//全局css样式
// import '@/assets/css/reset.css'//全局css样式
import '@/assets/css/style.scss'//全局css样式

import GLightbox from 'glightbox';
import 'glightbox/dist/js/glightbox.min.js';
import AOS from 'aos';
import 'aos/dist/aos.css';

import VueWaypoint from 'vue-waypoint'

// import 'swiper/swiper-bundle.min.css'

AOS.init(
  {
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  }
);

Vue.config.productionTip = false
Vue.prototype.$GLightbox = GLightbox;
Vue.use(VueWaypoint)
// import globalUpdate from '@/utils/globalUpdate'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

document.addEventListener('keydown', function (event) {//去除表单回车刷新页面
  // console.log(event)
  if (event.target && event.target.form === null) return; // 只对表单元素生效
  // debuger
  if (event.code !== "Enter") return; // 判断按下的键是否为 Enter 键
  if (event.target.type === "textarea" && event.target.form !== null) return // 忽略表单textarea元素

  event.preventDefault(); // 取消默认行为（即不会刷新页面）
});
document.addEventListener("click", function (evt) {
  // console.log(evt)
  let target = evt.target;
  if (target.nodeName == "SPAN") {
    target = evt.target.parentNode;
  }
  if (target.nodeName == "BUTTON") {
    target.blur();

  }
  // console.log(target)
})
// var lastClickTime=0,count=0
// document.addEventListener('click', function (event) {//控制台显示
//   console.log(event.target.classList)
//   let target = event.target;
//   if (target.nodeName == "SPAN"|| target.nodeName == 'I') {
//     target = event.target.parentNode;
//   }
//   if(target.nodeName=="BUTTON"){
//     target.blur();
//   }
//   if(event.target.classList.length>0&&event.target.classList.value.indexOf("vc-")!=-1){
//     count=0
//     return
//   }
//   const nowTime = new Date().getTime();
//   if (nowTime - lastClickTime < 1000) {
//     count++;
//   } else {
//     count = 0;
//   }
//   lastClickTime = nowTime;
//   if (count >= 10) {
//     let vconDom = document.getElementById('__vconsole');
//     consoleFu.toggleClass(vconDom, 'show')
//     count = 0;

//   }
// });
