import request from '@/utils/request'
import md5 from "js-md5"
// 登录
export function Login(data) {
    var data = {
        username: data.username,
        password: md5(data.password),
    }
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

//图片模块(banner、合作单位)
export function addVideo(data) {
    return request({
        url: '/addVideo',
        method: 'post',
        data
    })
}
export function updateVideo(data) {
    return request({
        url: '/updateVideo',
        method: 'post',
        data
    })
}
export function delVideo(data) {
    return request({
        url: '/delVideo',
        method: 'post',
        data
    })
}
export function getVideoList(data) {
    return request({
        url: '/getVideoList',
        method: 'post',
        data
    })
}
export function getVideoTypeList(data) {
    return request({
        url: '/getVideoTypeList',
        method: 'post',
        data
    })
}
export function getPicTypeList(data) {
    return request({
        url: '/getPicTypeList',
        method: 'post',
        data
    })
}




export function addPic(data) {
    return request({
        url: '/addPic',
        method: 'post',
        data
    })
}
export function delPic(data) {
    return request({
        url: '/delPic',
        method: 'post',
        data
    })
}
export function updatePic(data) {
    return request({
        url: '/updatePic',
        method: 'post',
        data
    })
}
export function getPicList(data) {
    return request({
        url: '/getPicList',
        method: 'post',
        data
    })
}

//招聘模块
export function getZhaopinList(data) {
    return request({
        url: '/getZhaopinList',
        method: 'post',
        data
    })
}
export function delZhaopin(data) {
    return request({
        url: '/delZhaopin',
        method: 'post',
        data
    })
}
export function updateZhaopin(data) {
    return request({
        url: '/updateZhaopin',
        method: 'post',
        data
    })
}
export function addZhaopin(data) {
    return request({
        url: '/addZhaopin',
        method: 'post',
        data
    })
}
export function getGuestList(data) {
    return request({
        url: '/getGuestList',
        method: 'post',
        data
    })
}
export function delFuwuqiPic(data) {
    return request({
        url: '/delFuwuqiPic',
        method: 'post',
        data
    })
}
export function getProjectList(data) {
    return request({
        url: '/getProjectList',
        method: 'post',
        data
    })
}
export function delProject(data) {
    return request({
        url: '/delProject',
        method: 'post',
        data
    })
}
export function updateProject(data) {
    return request({
        url: '/updateProject',
        method: 'post',
        data
    })
}
export function addProject(data) {
    return request({
        url: '/addProject',
        method: 'post',
        data
    })
}
export function addDaohangType1(data) {
    return request({
        url: '/addDaohangType1',
        method: 'post',
        data
    })
}
export function updateDaohangType1(data) {
    return request({
        url: '/updateDaohangType1',
        method: 'post',
        data
    })
}
export function delDaohangType1(data) {
    return request({
        url: '/delDaohangType1',
        method: 'post',
        data
    })
}
export function getDaohangTypeList(data) {
    return request({
        url: '/getDaohangTypeList',
        method: 'post',
        data
    })
}
export function addDaohangType2(data) {
    return request({
        url: '/addDaohangType2',
        method: 'post',
        data
    })
}
export function updateDaohangType2(data) {
    return request({
        url: '/updateDaohangType2',
        method: 'post',
        data
    })
}
export function delDaohangType2(data) {
    return request({
        url: '/delDaohangType2',
        method: 'post',
        data
    })
}
export function addWenzhang(data) {
    return request({
        url: '/addWenzhang',
        method: 'post',
        data
    })
}
export function updateWenzhang(data) {
    return request({
        url: '/updateWenzhang',
        method: 'post',
        data
    })
}
export function delWenzhang(data) {
    return request({
        url: '/delWenzhang',
        method: 'post',
        data
    })
}
export function getWenzhangList(data) {
    return request({
        url: '/getWenzhangList',
        method: 'post',
        data
    })
}
export function getWenzhangOneInfo(data) {
    return request({
        url: '/getWenzhangOneInfo',
        method: 'post',
        data
    })
}
export function getWenzhangOneshangxia(data) {
    return request({
        url: '/getWenzhangOneshangxia',
        method: 'post',
        data
    })
}
export function getOperationEmailList(data) {
    return request({
        url: '/getOperationEmailList',
        method: 'post',
        data
    })
}
export function delOperationEmail(data) {
    return request({
        url: '/delOperationEmail',
        method: 'post',
        data
    })
}
export function updateOperationEmail(data) {
    return request({
        url: '/updateOperationEmail',
        method: 'post',
        data
    })
}
export function addOperationEmail(data) {
    return request({
        url: '/addOperationEmail',
        method: 'post',
        data
    })
}



export function addZiyuan(data) {
    return request({
        url: '/addZiyuan',
        method: 'post',
        data
    })
}
export function updateZiyuan(data) {
    return request({
        url: '/updateZiyuan',
        method: 'post',
        data
    })
}
export function delZiyuan(data) {
    return request({
        url: '/delZiyuan',
        method: 'post',
        data
    })
}
export function getZiyuanList(data) {
    return request({
        url: '/getZiyuanList',
        method: 'post',
        data
    })
}
export function getZiyuanTypeList(data) {
    return request({
        url: '/getZiyuanTypeList',
        method: 'post',
        data
    })
}