// import {log} from "@/api/response" //请求后端做记录

const state = {
    monitoring:[],
}

const mutations = {
    setMonitoring(state,data){
        var json={
            ...data,
            nowPage:window.location.href,
            steps:state.monitoring.length+1,
            time:`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}-${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
          }
        state.monitoring.push(json)
        // log(json).then((res)=>{
        //     console.log(res)
        //   }).catch(err => {
        //       // 报错
        //       console.log(err)
        //     })
      }
}

const actions = {
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
