// import {log} from "@/api/response" //请求后端做记录

const state = {
    userjilu: {},
}

const mutations = {
    setUserjilu(state, data) {
        if (!data) {
            state.userjilu = {}
        } else {
            var json = {
                ...data,

            }
            state.userjilu=json
        }

        // log(json).then((res)=>{
        //     console.log(res)
        //   }).catch(err => {
        //       // 报错
        //       console.log(err)
        //     })
    }
}

const actions = {
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
