<template>
  <section class="luoboBox">
    <div class="row Box m-0">
      <div class="col-lg-4 col-md-4 menuBox">
        <div
          v-for="(item, index) in bannarList"
          :key="index"
          class="menu row align-items-center justify-content-center"
          :class="{ xz_menu: num == index }"
          @click="luboFun(index)"
        >
          <div
            class="line"
            :style="{
              '--py': (xuanzhuan - 1) * 20 + '%',
            }"
            :class="{grow:num == index}"
            v-if="num == index"
          ></div>
          <div class="col-lg-2 col-md-2">
            <svg-icon
              :icon-class="num == index ? item.iconName + '_xz' : item.iconName"
              class="icon"
            />
          </div>
          <div class="col-lg-8 col-md-8">
            <div class="titleCh mb-1">{{ item.name }}</div>
            <div class="titleEn">{{ item.name_en }}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 p-0 ImgBox">
        <img :src="bannarList[num].pic_name" class="w-100" />
        <div class="xinxi">
          <div class="titleCh mb-2">{{ bannarList[num].name }}</div>
          <div class="description">{{ bannarList[num].zhaiyao }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "luobocom",
  props: {
    //传值
    bannarList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      num: 0,
      xuanzhuan: 1,
      jishi: null,
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("luobocom");
    var that = this;
    this.jishi = setInterval(function () {
      if (that.xuanzhuan < 6) {
        that.xuanzhuan++;
      } else {
        that.xuanzhuan = 1;
        if (that.num == 2) {
          that.num = 0;
        } else {
          that.num++;
        }
      }
    }, 1000);
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    luboFun(item) {
      console.log(item);
      this.xuanzhuan = 0;
      clearInterval(this.jishi);
      this.num = item;
      var that = this;
      this.jishi = setInterval(function () {
        if (that.xuanzhuan < 6) {
          that.xuanzhuan++;
        } else {
          that.xuanzhuan = 1;
          if (that.num == 2) {
            that.num = 0;
          } else {
            that.num++;
          }
        }
      }, 1000);
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
$design-width: 192;
@function pxttrem($px) {
  @return calc($px/$design-width) * 10 + rem;
}
.luoboBox {
  // padding: 80px 0 0 0;
  width: 100%;
  height: 100%;
  .Box {
    width: 100%;
    height: 100%;
    .menuBox {
      height: 100%;
      background: #fafafa;
      padding: pxttrem(80) 0 0 0;
      .xz_menu {
        background: #0040bc;
        position: relative;
        .line {
          position: absolute;
          width: pxttrem(10);
          height: pxttrem(3);
          bottom: 0;
          left: 0;
          border-bottom: pxttrem(3) solid #1ae25e;
          // background: ;
           transition: width 1s linear;
        }
        .grow {
          width: var(--py); /* 线条的目标长度为父容器的100% */
        }
        .titleCh {
          color: #fff !important;
        }
        .titleEn {
          color: #fff !important;
        }
        &:hover {
          // background: #0040bc;
          .titleCh {
            opacity: 1 !important;
          }
        }
      }
      .menu {
        cursor: pointer;
        height: 33.33%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: pxttrem(20);
        color: #25272a;
        line-height: pxttrem(23);
        text-align: left;
        font-style: normal;
        text-transform: none;
        border-bottom: pxttrem(1) solid rgb(37 39 42 / 50%);
        &:hover {
          // background: #0040bc;
          .titleCh {
            opacity: 0.7;
          }
        }
        .icon {
          width: 3em;
          height: 3em;
          color: #0040bc;
        }
        .titleCh {
          font-weight: 600;
          font-size: pxttrem(32);
          color: #0040bc;
          line-height: pxttrem(38);
        }
        .titleEn {
          opacity: 0.7;
        }
      }
    }
    .ImgBox {
      position: relative;
      height: 100%;
      overflow: hidden;
      img {
        transition: all 0.5s ease;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      .xinxi {
        position: absolute;
        bottom: 10%;
        left: 10%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: pxttrem(24);
        color: #ffffff;
        line-height: pxttrem(43);
        text-align: left;
        font-style: normal;
        text-transform: none;
        z-index: 1;
        .titleCh {
          font-weight: 600;
          font-size: pxttrem(40);
          color: #ffffff;
          line-height: pxttrem(47);
        }
        .description {
          font-size: pxttrem(18);
        }
      }
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(rgb(127 135 144 / 0%) 35%, #25272a 100%);
        opacity: 0.6;
      }
    }
  }
}
</style>