<!--
 * 图片上传弹窗 
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :fullscreen="fullscreen"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <slot>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        class="demo-ruleForm"
      >
      <el-form-item label="图片类型" prop="pic_type_id">
          <el-select
            v-model="ruleForm.pic_type_id"
            placeholder="请选择"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in PicTypeList"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份" prop="year" v-if="ruleForm.pic_type_id == 8">
          <el-input v-model="ruleForm.year"></el-input>
        </el-form-item>
        <el-form-item label="月份" prop="month" v-if="ruleForm.pic_type_id == 8">
          <el-input v-model="ruleForm.month"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name" v-if="ruleForm.pic_type_id != 8">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="英文标题" prop="name_en" v-if="ruleForm.pic_type_id != 8">
          <el-input v-model="ruleForm.name_en"></el-input>
        </el-form-item>
        <el-form-item label="摘要" prop="zhaiyao">
          <el-input v-model="ruleForm.zhaiyao" type="textarea" :autosize="{ minRows: 5 }"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="tag" v-if="ruleForm.pic_type_id != 8">
          <el-input v-model="ruleForm.tag"></el-input>
        </el-form-item>
        

        <el-form-item label="上传图片" prop="pic_name" v-if="ruleForm.pic_type_id != 11&&ruleForm.pic_type_id != 12">
          <big-file
            ref="bigFile"
            accept=".jpg,.png"
            :showFileList="false"
            :formData="bigfileFormData"
            @upload="upload($event)"
            @uploadEvent="uploadEvent($event)"
            @uploadSpeed="uploadSpeed($event)"
          >
            <template v-if="ruleForm.pic_name != ''">
              <div @click="delUpload" class="delUploadBox">
                <span>{{ ruleForm.pic_name }}</span
                ><i class="el-icon-circle-close delUpload"></i>
              </div>
            </template>
          </big-file>
        </el-form-item>
        <el-form-item label="是否是外部链接" prop="is_wai" v-if="ruleForm.pic_type_id != 11&&ruleForm.pic_type_id != 12">
          <el-switch
            v-model="ruleForm.is_wai"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="是"
            inactive-value="否"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否启用" prop="is_ok">
          <el-switch
            v-model="ruleForm.is_ok"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="启用"
            inactive-value="不启用"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="链接地址" prop="tiaozhuan" v-if="ruleForm.pic_type_id != 11&&ruleForm.pic_type_id != 12">
          <el-input
            v-model="ruleForm.tiaozhuan"
            v-if="ruleForm.is_wai == '是'"
          ></el-input>
          <el-cascader
            v-model="ruleForm.tiaozhuan"
            :options="tiaozhuanList"
            :props="{ checkStrictly: true, value: 'path', label: 'title' }"
            clearable
            filterable
            placeholder="请选择"
            style="width: 100%"
            v-else
          ></el-cascader>
        </el-form-item>
        <el-form-item label="排序" prop="paixu">
          <el-input-number
            v-model="ruleForm.paixu"
            :min="0"
            controls-position="right"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </slot>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handeclose">取 消</el-button>
      <el-button type="primary" @click="handeSumbit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getPicTypeList } from "@/api/adminHoutai";
export default {
  name: "imageTanchuang",
  props: {
    //传值
    width: {
      type: String,
      default: "30%",
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "提示",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PicTypeList: [],
      uploadValue: {},
      ruleForm: {
        name: "",
        name_en: "",
        pic_type_id:"",
        pic_name: "",
        zhaiyao: "",
        tiaozhuan: "",
        is_wai: "否",
        is_ok: "启用",
        tag:"",
        year:null,
        month:null,
        paixu: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        year: [{ required: true, message: "请输入年份", trigger: "change" }],
        month: [{ required: true, message: "请输入月份", trigger: "change" }],
        pic_type_id: [
          { required: true, message: "请选择图片类型", trigger: "change" },
        ],
        // pic_name: [
        //   { required: true, message: "请上传图片", trigger: "change" },
        // ],
      },
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    bigfileFormData() {
      var json = {
        pic_path: "pic",
        flag: "guanwang_pic",
      };
      return json;
    },
    tiaozhuanList() {
      var arr = filterRoutes(this.$deepCopy(this.$router.options.routes));
      console.log(arr);
      function filterRoutes(routes, path = "", id = "") {
        return routes.filter((route, index) => {
          // console.log(route.path,path)
          route.path =  path + route.path;
          route.id =
            id == "" ? String(index + 1) : String(id) + String(index + 1);
          // console.log(route.alwaysShow,"route.alwaysShow")
          if (route.hidden && !route.alwaysShow) {
            return false;
          } else {
            if (route.children) {
              route.children = filterRoutes(
                route.children,
                route.path == "/" ? route.path :  "/",
                route.id
              );
            }
            return true;
          }
        });
      }
      console.log(this.$deepCopy(arr), "arr");
      arr = chchongzhu(arr);
      console.log(arr);
      function chchongzhu(arr) {
        return arr.map((item, index) => {
          if (!item.children) {
            return { id: item.id, path: item.path, title: item.meta.title };
          }
          if (item.children.length == 1 && !item.alwaysShow) {
            return {
              id: item.children[0].id,
              path: item.children[0].path,
              title: item.children[0].meta.title,
            };
          } else {
            item.children = chchongzhu(item.children);
            return {
              id: item.id,
              path: item.path,
              title: item.meta.title,
              children: item.children,
            };
          }
        });
      }
      arr.shift();
      return arr;
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("imageTanchuang");
    this.getPicType();
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    getPicType() {
      getPicTypeList()
        .then((res) => {
          console.log(res);
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.PicTypeList = res.data.list;
          }
        })
        .catch((err) => {
          // 报错
          console.log(err);
        });
    },
    handeclose() {
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
       if(this.$refs.bigFile){
         this.$refs.bigFile.form = {
          filelist: [],
        };
        this.$refs.bigFile.upflag = {};
       }
      }

      this.$emit("update:dialogVisible", false);
    },
    handeSumbit() {
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.validate((valid) => {
          if (!valid) return;
          var json=this.$deepCopy(this.ruleForm)
          if(json.tiaozhuan){if(json.is_wai == "否"&&json.tiaozhuan.length>0){
             json.tiaozhuan = json.tiaozhuan.join("");
          }}
         
          this.$emit("sumbitAdd", json);
          this.handeclose();
        });
      } else {
        var that = this;
        this.$emit("sumbitAdd", function () {
          return that.handeclose();
        });
      }
    },

    /**
     *  执行大文件上传
     */
    upload(event) {
      /* 执行大文件上传 */
      // this.$refs.bigFile.upload(event);

      this.$refs.bigFile.upload(event, true); //必须这样传回给子组件
    },
    /** 大文件上传的进度 */
    uploadSpeed(val) {
      /* 大文件上传的进度 */
      console.log(val);
      this.uploadValue = val;
    },
    /** 大文件上传成功返回内容 */
    uploadEvent(val) {
      /* 大文件上传成功返回内容 */
      console.log(val);
      this.ruleForm.pic_name = val;
    },
    delUpload() {
      this.$refs.bigFile.handleRmove(this.ruleForm.pic_name);
      this.ruleForm.pic_name = "";
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.delUpload {
  margin-left: 10px;
}
.delUploadBox {
  cursor: pointer;
}
</style>