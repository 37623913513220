<template>
  <div class="d-flex houtai">
    <headerViewRight :navList="routeList"></headerViewRight>
    <section class="section flex-grow-1">
      <keep-alive>
        <router-view :key="key" v-if="$route.meta.noCache" />
      </keep-alive>
      <router-view :key="key" v-if="!$route.meta.noCache" />
    </section>
    <!-- 
    <section class="section" >
      <router-view />
    </section>
    <Footer></Footer> -->
    <!-- <el-container class="pageBox">
      <el-header class="headerBox">
        <logo />
        <top-nav v-if="$globalVariable.menuDirection == 'top'" />
        <user-box class="userBox" />
      </el-header>
      <el-container class="root">
        <el-container>
          <el-main class="pageHome">
            <el-container>
              <el-aside
                class="sideBar"
                v-if="$globalVariable.menuDirection == 'right'"
              >
                <right-nav></right-nav>
              </el-aside>
              <el-main class="pageMain">
                <keep-alive>
                  <router-view :key="key" v-if="$route.meta.noCache" />
                </keep-alive>
                <router-view :key="key" v-if="!$route.meta.noCache" />
              </el-main>
            </el-container>
            <el-footer>
              <footer-Box></footer-Box>
            </el-footer>
          </el-main>
        </el-container>
      </el-container>
    </el-container> -->
  </div>
</template>

<script>
export default {
  name: "Layout",
  props: {
    //传值
  },
  metaInfo() {
    return {
      title: "极智基因|极智生物-天津极智基因科技有限公司",
      meta: [
        {
          charset: "utf-8",
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },
        {
          name: "keywords",
          content: "极智基因,极智生物,天津极智基因科技有限公司",
        },
        {
          name: "description",
          content:
            "极智基因|极智生物-天津极智基因科技有限公司主要从事基因科技服务和智慧育种应用，业务覆盖基因测序、生物信息分析服务、生物云分析平台和分子育种设计等方向。 极智基因专注于基因组学技术的应用，致力于全球测序服务，提供前沿科研产品服务和解决方案。 公司优势产品丰富，包括动植物基因组学、群体遗传学、转录调控、代谢组和蛋白组等多组学产品。 公司团队成员项目经验丰富，以硕博为主，目前已拥有多项著作权及研发专利。",
        },
        {
          name: "robots",
          content: "index,follow",
        },
        {
          name: "GOOGLEBOT",
          content: "index,follow",
        },
        {
          name: "Author",
          content: "天津极智基因科技有限公司",
        },
      ],
    };
  },
  data() {
    return {};
  },
  components: {
    //引入模块
    Topbar: () => import("@/components/Navbar/topBar"),
    headerViewRight: () => import("@/components/Navbar/headerViewRight"),
    topNav: () => import("@/components/Navbar/topNav"),
    rightNav: () => import("@/components/Navbar/rightNav"),
    logo: () => import("@/components/logo/index"),
    userBox: () => import("@/components/userBox/index"),
    footerBox: () => import("@/components/footerBox/index"),
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    key() {
      return this.$route.path;
    },

    routeList() {
      var arr = filterRoutes(this.$deepCopy(this.$router.options.routes));
      console.log(arr);
      function filterRoutes(routes, path = "", id = "") {
        return routes.filter((route, index) => {
          route.path = path + route.path;
          route.id =
            id == "" ? String(index + 1) : String(id) + String(index + 1);
          if (
            route.path.indexOf("smart-admin") == -1 ||
            route.path.indexOf("smart-admin/login") > -1
          ) {
            return false;
          } else {
            if (route.children) {
              route.children = filterRoutes(
                route.children,
                route.path == "/" ? route.path : route.path + "/",
                route.id
              );
            }
            return true;
          }
        });
      }
      arr = filterRoutes1(arr[0].children);
      function filterRoutes1(routes) {
        return routes.filter((route) => {
          if (route.hidden && !route.alwaysShow) {
            return false;
          } else {
            if (route.children) {
              route.children = filterRoutes1(route.children);
            }
            return true;
          }
        });
      }
      console.log(arr);
      arr = chchongzhu(arr);
      console.log(arr);
      function chchongzhu(arr) {
        return arr.map((item, index) => {
          if (!item.children) {
            return { id: item.id, path: item.path, title: item.meta.title };
          }
          if (item.children.length == 1 && !item.alwaysShow) {
            return {
              id: item.children[0].id,
              path: item.children[0].path,
              title: item.children[0].meta.title,
            };
          } else {
            item.children = chchongzhu(item.children);
            return {
              id: item.id,
              path: item.path,
              title: item.meta.title,
              children: item.children,
            };
          }
        });
      }
      arr = arr[0].children ? arr[0].children : arr;
      return arr;
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
$design-width: 192;

@function pxttrem($px) {
  @return calc($px / $design-width) * 10 + rem;
}

@media (max-width: 991px) {
  .d-flex {
    display: block !important;
  }

  .section {
    width: 100% !important;
  }
}

.houtai {
  .section {
    width: calc(100% - pxttrem(280));
    height: calc(100vh - pxttrem(20));
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.25);
    padding: 0 !important;

    &.header-top {
      margin-top: pxttrem(130);
    }

    &-title {
      text-align: center;
      padding-bottom: pxttrem(30);

      h3 {
        font-size: 1.5rem;
        /* 字符间距 */
        letter-spacing: 1px;
        font-weight: 600;
        padding: 8px 20px;
        margin: 0;
        background: #dceafb;
        color: #222222;
        display: inline-block;
        /* text-transform: uppercase; */
        border-radius: 50px;

        span {
          font-size: 1.5rem;
          font-weight: 600;
          color: #106eea;
        }
      }

      p {
        margin: pxttrem(15) auto 0 auto;
        font-weight: 600;
        line-height: 1.5rem;

        @media (min-width: 1024px) {
          width: 50%;
        }
      }
    }
  }
}
</style>
