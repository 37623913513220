<template>
  <div class="diy_notification_box" v-if="flag">
    <div class="diy_notification_mark"></div>
    <div class="diy_notification">
      <div class="diy_notification_tag">
        <svg-icon :icon-class="json.type" class="diy_notification_tag_svg" />
      </div>
      <div class="diy_notification_title">
        {{ json.title }}
      </div>
      <div class="diy_notification_conent" v-html="json.message"></div>
      <div class="button_group">
        <el-button
          type="danger"
          size="mini"
          @click="onError"
          v-if="!json.btn.close.hidden"
          >{{ json.btn.close.label }}</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="onSuccess"
          v-if="!json.btn.success.hidden"
          >{{ json.btn.success.label }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notificationBox",
  props: {
    //传值
  },
  data() {
    return {
      flag: true,
      json: {
        title: "",
        message: "",
        duration: 0,
        type: "",
        btn: {
          success: { label: "更新", hidden: false },
          close: { label: "关闭", hidden: true },
        },
      },
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    if(this.json.duration>0){
        setTimeout(() => {
            this.flag = false;
        }, json.duration);
    }
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    onSuccess() {
      this.flag = false;
    },
    onError() {
      this.flag = false;
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.diy_notification_box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
}
.diy_notification_mark {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}
.diy_notification {
  top: 50vh;
  width: 300px;
  margin-left: -150px;
  position: absolute;
  left: 50%;
  background: #fff;
  height: 200px;
  margin-top: -100px;
  padding: 20px;
  border-radius: 8px;
}
.diy_notification_tag {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -50px;
  background: #fff;
  border-radius: 50px;
}
.diy_notification_title {
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  font-weight: bold;
}
.diy_notification_conent {
  font-size: 14px;
  text-align: left;
  margin-top: 20px;
  line-height: 2;
}
.diy_notification_tag_svg {
  width: 100% !important;
  height: 100% !important;
}
.diy_notification .button_group {
  margin-top: 20px;
  text-align: center;
}
</style>